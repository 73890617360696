<template>
  <div>    
    <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

      <!-- Loading screen -->
      <div v-if="isLoading" key="animation">
        <LoadingScreen />
      </div>

      <!-- TherapyArea page -->
      <div
        v-if="!isLoading"
        class="flex flex-wrap sm:flex-no-wrap mx-auto pl-2 sm:pl-6 lg:pl-8"
        key="content"
      >

        <!-- Left navigation menu -->
        <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">
          <ClassThemesList
            :themes="themes"
            :activeTheme="currentTheme"
            @theme-click="themeClick($event)"
          />
        </fade-transition>

        <div class="w-full mx-auto md:w-4/5 px-2 md:px-6">

          <!-- Content header -->
          <ClassContentHeader
            :title="title"
            :theme="themeName"
            className="Онкокласс"
            @area-click="areaClick()"
            @theme-click="themeClick(null)"
          />

          <router-view name="content" :data="filteredContent" :isLoading="isLoading" />
        </div>
      </div>
    </fade-transition>
    <Footer /> 
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'

import ClassThemesList from '@/components/therapyarea/ClassThemesList.vue'
import ClassContentHeader from '@/components/therapyarea/ClassContentHeader.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'OncoClass',
  components: {
    ClassThemesList,
    ClassContentHeader,
    Footer,
    LoadingScreen,
    FadeTransition
  },
  data: function () {
    return {
      isLoading: true,
      themes: null,
      currentTheme: null,
      videos: null,
      currentVideo: null
    }
  },
  computed: {
    ...mapGetters({
      contentName: 'getCurrentContentName'
    }),
    title() {
      if (this.$route.name === 'Video')
        return this.contentName
      return 'Видео-библиотека'
    },
    themeName() {
      if (this.currentTheme)
        return this.themes.find((theme) => theme.id == this.currentTheme).name 
    },
    filteredContent() {
      if (this.currentTheme) {
        return this.videos.filter(video => video.theme_id.some(element => {
          return this.currentTheme == element
        }))
      } else {
        return this.videos
      }
    }
  },
  methods: {
    ...mapActions([
      'getOncoClass'
    ]),
    themeClick(id) {
      this.currentTheme = id
      if (this.$route.name !== 'OncologyClass')
        this.$router.push({ name: 'OncologyClass' })
    },
    areaClick() {
      this.currentTheme = null
      if (this.$route.name !== 'OncologyClass')
        this.$router.push({ name: 'OncologyClass' })
    }
  },
  created: function() {
    this.getOncoClass()
      .then((data) => {
        this.themes = data.themes
        this.videos = data.videos
        this.isLoading = false
      })
      .catch(() => {
        this.$router.replace({ name: 'Not found' })
      })
  }
}
</script>