<template>
  <div>
    <!-- Content title -->
    <h1 
      class="block text-3xl text-secondary text-left pt-8 pb-3 pr-2 md:pr-4 lg:pr-8"
      style="line-height: 1.2"
    >{{ title }}</h1>

    <!-- BreadCrumbs -->
    <div v-if="!noBreadCrumbs" class="text-left sm:pr-6 lg:pr-8 pb-3">
      <button
        :class="[{'underline text-gray-600 hover:text-primary-500': nosologyName, 
          'text-primary-500 font-semibold': !nosologyName}]"
        @click="$emit('area-click')"
      >
        {{ className }}
      </button>

      <button
        v-if="theme"
        @click="$emit('theme-click')"
        class="text-gray-600 hover:text-primary-500"        
      >
      	<span class="text-gray-800 font-medium" v-html="'&nbsp;>&nbsp;'"></span>
        <span>{{ theme }}</span>
      </button>
    </div>

    <!-- Stickers -->
    <div v-if="isItListRoute && isUserHaveAccess" class="flex flex-wrap pb-3 sm:pr-6 lg:pr-8">
      <button 
        v-for="stick in sticks"
        :key="stick.title"
        class="px-6 py-1 border border-solid rounded-full mr-2 mb-4 text-sm font-semibold"
        :class="[{'bg-primary-500 text-white border-primary-500': stick.isActive,
          'border-secondary text-secondary bg-gray-100 hover:text-primary-500 hover:border-primary-500': !stick.isActive}]"
        v-on:click="stick.action"
      >
        {{ stick.title }}
      </button>
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'ClassContentHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      required: false
    },
    className: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      video: state => state.videos.currentVideo,
      showViewed: state => state.showViewed
    }),
    ...mapGetters({
      isItListRoute: 'isItListRoute',
      isFiltersActive: 'isFiltersActive',
      isUserHaveAccess: 'isUserHaveAccess',
      themeName: 'getCurrentThemeName',
      nosologyName: 'getCurrentNosologyName',
      contentName: 'getCurrentContentName'
    }),
    sticks() {
      if (this.$route.name == 'Videos') return [
        {
          isActive: this.showViewed == null,
          title: "Все",
          action: this.setToAll
        },
        {
          isActive: this.showViewed == true,
          title: "Просмотренные",
          action: this.setToViewed
        },
        {
          isActive: this.showViewed == false,
          title: "Не просмотренные",
          action: this.setToNotViewed
        }
      ]
      if (this.$route.name == 'Tests') return [
        {
          isActive: this.showViewed == null,
          title: "Все",
          action: this.setToAll
        },
        {
          isActive: this.showViewed == true,
          title: "Завершенные",
          action: this.setToViewed
        },
        {
          isActive: this.showViewed == false,
          title: "Не завершенные",
          action: this.setToNotViewed
        }
      ]
    },
    noBreadCrumbs() {
      if (this.$route.name == 'Experts') return true
      if (this.$route.name == 'Partners') return true
      if (this.$route.name == 'Contacts') return true
      if (this.$route.name == 'ClinicalCases') return true
      if (this.$route.name == 'ClinicalCase') return true
      if (this.$route.name == 'Redcollege') return true
      return false
    },
  },
  methods: {
    ...mapActions([
      // Stickers actions
      'setToAll', 'setToViewed', 'setToNotViewed', 

      // Breadcrumbs actions
      'setToIndex', 'resetTheme'
    ]),
    therapyAreaClick() {
      if (!this.isItListRoute) {
        this.setToIndex()
        this.goToList()
      } else if (this.nosologyName) {
        this.setToIndex()
      }
    },
    nosologyClick() {
      if (!this.isItListRoute) {
        this.resetTheme()
        this.goToList()
      } else if (this.themeName) {
        this.resetTheme()
      }
    },
    themeClick() {
      if (!this.isItListRoute) {
        this.goToList()
      }
    },
    // Вернуться к списку в текущем разделе
    goToList() {
      let listRoute
      if (listRoute = this.$route.path.match(/\/.*\/.*\//)) this.$router.push(listRoute[0])
    }
  }
}
</script>
