<template>
  <div class="w-full sm:w-1/4 lg:w-1/5" style="min-width:200px">
    <div
      class="ml-2 mt-4 mr-4 sm:ml-0 sm:mr-3 sm:my-8 shadow-md rounded-lg h-auto bg-primary-600"
    >
      <h2 
        v-on:click="showNosology = !showNosology"
        class="flex text-xl justify-center px-4 py-3 border-2 rounded-lg
        border-primary-500 bg-gray-200 text-primary-600 sm:block
        sm:text-white sm:font-semibold sm:bg-primary-500"
      >
        {{ therapyAreaName }}
        <font-awesome-icon 
          :class="[{'block': !showNosology, 'hidden': showNosology}]"
          class="ml-auto sm:hidden" 
          icon="sort-down"
        />
        <font-awesome-icon 
          :class="[{'block': showNosology, 'hidden': !showNosology}]"
          class="ml-auto sm:hidden mt-auto" 
          icon="sort-up"
        />
      </h2>

      <!-- Выпадающий список -->
      <div
        :class="[{'block': showNosology, 'hidden': !showNosology}]"
        class="sm:block"
      >
        <div
          v-for="theme in filteredThemes"
          :key="theme.id"
          :class="[{'bg-white text-gray-700': activeTheme == theme.id,
            'text-gray-200 hover:text-white hover:bg-primary-500': activeTheme != theme.id}]"
          class="flex items-center cursor-pointer py-3 px-3 border-b border-white
            text-left text-sm"
          @click="$emit('theme-click', theme.id)"
        >
          <span class="pl-3">{{ theme.name }}</span>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ClassThemesList',
  props: {
    themes: {
      type: Array,
      required: true
    },
    activeTheme: {
      type: Number,
      required: false
    }
  },
  data: function() {
    return {
      showNosology: false
    }
  },
  computed: {
    ...mapState({
      'therapyAreaName': state => state.therapyArea.name,
    }),
    filteredThemes() {
      return this.themes.filter((theme) => theme.videosCount !== 0)
    }
  },
  methods: {
    mobileThemesClose() {
      this.showNosology = false
    }
  }
}
</script>
